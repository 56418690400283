<template>
	<div class="not-found">
		<div class="view-header">
			<img class="view-header__image" src="@/assets/foo-404.png">
			<h1 class="title" v-markdown:title>
				*Upss...* esta página se ha ido.
			</h1>
			<p class="view-header__description">
				Es posible que hicieras clic en un enlace ya caducado o escribiste mal la dirección. 
			</p>
			<router-link class="lnk" :to="{ name: 'home' }">
				Inicio
			</router-link>
		</div>
	</div>
</template>

<script>
	export default {
		name: "NotFound404"
	}
</script>

<style scoped>
.view-header {
	min-height: 75vh;
}
</style>